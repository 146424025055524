import axios from "axios";

/* 所有的通用功能方法 */
const comfn = {
  /* **
   * 通用的ajax请求封装
   * 参数说明：
   *   nologin: 不验证登录
   *   url: 请求接口地址，默认为doMain的值
   *   type: 请求类型 -- get、post、put、delete等等
   *   headers: 请求头信息
   *   params: 接口请求参数
   * promise参数说明：
   *   resolve: 成功的回调
   *   reject: 失败的回调
   ** */
  doAjax(obj) {
    // 验证是否登录
    let headers = obj.headers ? obj.headers : {};
    let withCredentials =
      obj.withCredentials !== undefined ? obj.withCredentials : true;
    if (!headers["Content-Type"] && !headers["content-type"]) {
      headers["Content-Type"] = "application/x-www-form-urlencoded";
    }

    if (!obj.url) {
      console.error("comfn.doAjax方法 --- 请传入接口地址。");
      return false;
    }
    return new Promise((resolve, reject) => {
      let req_params = {
        url: obj.url,
        method: obj.type || "POST",
        headers,
        /*
        如果前端配置了这个withCredentials=true，后段设置Access-Control-Allow-Origin不能为 " * ",必须是你的源地址
        header("Access-Control-Allow-Origin","源地址";
        header("Access-Control-Allow-Credentials", "true");
        */
        withCredentials,
        timeout: 50000,
      };
      if (obj.type == "POST" || obj.type == "PUT" || obj.type == "PATCH") {
        req_params["data"] = obj.params;
      } else {
        req_params["params"] = obj.params;
      }

      service
        .request(req_params)
        .then((res) => {
          resolve(res); // 请求成功
        })
        .catch((err) => {
          // 请求失败
          // todo：先做统一操作，再执行error回调
          if (err && err.response && err.response.status == 403) {
            if (url.indexOf(location.origin) != -1) {
              //非同源的403不处理，同源的才处理
              alert("登录已失效，请重新登录。");
              comfn.backLogin();
            }
          }
          reject(err);
          return false;
        })
        .finally(() => {
          // TODO: 请求结束后，保证代码能继续执行
          // 请求完成
        });
    });
  },

  /* 所有对时间操作的通用方法 */
  time: {
    /**
     * 转换某个指定时间
     * @module 通用组件---公用方法
     * @params 参数说明：
     *   str： 需要转换的时间，可以是时间戳，也可以是有格式的日期（可以精确到日，也可以精确到秒）
     *   char：转换后的连接符
     * @author maoyingcai
     * @email 619631130@qq.com
     **/
    getTimeObj(str, char) {
      // char = char ? char : "-"; // 日期中间的连接符，默认 -
      char = char ? char : char === undefined ? "-" : char === "" ? "" : "-"; //传连接符就用，不传就默认用 - ，传空字符串表示不用连接符连接，最后那个三元判断防止传null等这种极端情况
      let reg_timestr = /[-|/|:|\s]/;
      // 1、检查str 是否含有 - / : 这几个符号
      if (!reg_timestr.test(str)) {
        // 2、str 如果是 字符串类型的 时间戳，那么需要转换成数值类型
        let reg_num = /^[0-9]\d*$/;
        if (reg_num.test(str)) {
          if (str.toString().length > 10) {
            str = parseInt(str);
          } else {
            str = parseInt(str) * 1000;
          }
        }
      }

      let time_obj = {};
      let cur_time = "";
      if (str) {
        if (typeof str === "string" && str.indexOf("-") > -1) {
          str = str.replace(/-/g, "/"); // 使用通用的时间格式，进行转换
        }
        cur_time = new Date(str);
      } else {
        cur_time = new Date();
      }

      let time_str = cur_time.getTime();
      let nowday = cur_time.getDay(); // 今天是本周第几天，值为0(周日)~6(周六)
      let year = cur_time.getFullYear();

      let month = cur_time.getMonth() + 1;
      month = month < 10 ? "0" + month : month;

      let days = cur_time.getDate();
      days = days < 10 ? "0" + days : days;

      let hours = cur_time.getHours();
      hours = hours < 10 ? "0" + hours : hours;

      let mins = cur_time.getMinutes();
      mins = mins < 10 ? "0" + mins : mins;

      let seconds = cur_time.getSeconds();
      seconds = seconds < 10 ? "0" + seconds : seconds;

      let ym = year + char + month;
      let ymd = ym + char + days;
      let md = month + char + days;
      let mdhm = md + " " + hours + ":" + mins;
      let ymdh = ymd + " " + hours + ":00";
      let ymdhm = ymd + " " + hours + ":" + mins;
      let ymdhms = ymdhm + ":" + seconds;
      let hm = hours + ":" + mins;
      let hms = hours + ":" + mins + ":" + seconds;

      time_obj = {
        time_str, // 拿时间戳
        nowday, // 今天是本周第几天
        year, // 单独拿年份
        month, // 单独拿月份
        days, // 单独拿日
        hours, // 单独拿小时
        mins, // 单独拿分钟
        seconds, // 单独拿秒
        ym, // 2020-07
        ymd, // 2020-07-15
        md, // 07-15
        mdhm, // 07-15 19:08
        ymdh, // 2020-07-15 19:00
        ymdhm, // 2020-07-15 19:08
        ymdhms, // 2020-07-15 19:08:50
        hm, // 19:08
        hms, // 19:08:50
      };

      return time_obj;
    },

    /* **
     * 获取本周的开始日期
     * **/
    getWeekStartDate() {
      let curObj = comfn.time.getTimeObj();
      let days = 0;
      // curObj.nowday 是从周日开始算本周第一天的
      if (curObj.nowday == 0) {
        days = -6;
      } else {
        days = parseInt("-" + curObj.nowday) + 1;
      }
      let obj = {
        type: "day",
        days: days,
      };
      let weekStartDate = comfn.time.getRecentDate(obj);
      return weekStartDate;
    },

    /* **
     * 获取本周的结束日期
     * **/
    getWeekEndDate() {
      let curObj = comfn.time.getTimeObj();
      let days = 0;
      // curObj.nowday 是从周日开始算本周第一天的
      if (curObj.nowday == 0) {
        days = 0;
      } else {
        days = 7 + parseInt("-" + curObj.nowday);
      }
      let obj = {
        type: "day",
        days: days,
      };
      let weekEndDate = comfn.time.getRecentDate(obj);
      return weekEndDate;
    },

    /* **
     * 获得某月的天数
     * **/
    getMonthDays(_month) {
      let curObj = comfn.time.getTimeObj();
      let monthStartDate = new Date(curObj.year, _month, 1);
      let monthEndDate = new Date(curObj.year, _month + 1, 1);
      let days = (monthEndDate - monthStartDate) / (1000 * 60 * 60 * 24);
      return days;
    },

    /* **
     * 获得本月的开始日期
     * **/
    getMonthStartDate() {
      let curObj = comfn.time.getTimeObj();
      let monthStartDate = new Date(curObj.year, parseInt(curObj.month) - 1, 1);
      return comfn.time.getTimeObj(monthStartDate).ymd;
    },

    /* **
     * 获得本月的结束日期
     * **/
    getMonthEndDate() {
      let curObj = comfn.time.getTimeObj();
      let _month = parseInt(curObj.month) - 1;
      let monthEndDate = new Date(
        curObj.year,
        _month,
        comfn.time.getMonthDays(_month)
      );
      return comfn.time.getTimeObj(monthEndDate).ymd;
    },

    getYearStartDate() {
      let curObj = comfn.time.getTimeObj();
      let monthStartDate = new Date(curObj.year, 0, 1);
      return comfn.time.getTimeObj(monthStartDate).ymd;
    },

    /**
     * 计算两个日期之间的天数
     * @module 通用组件---公用方法
     * @author maoyingcai
     * @email 619631130@qq.com
     **/
    countDateDiff(stime, etime) {
      let startTime = comfn.time.getTimeObj(stime).time_str;
      let endTime = comfn.time.getTimeObj(etime).time_str;

      // abs 取绝对值    ceil 上舍
      let dates = Math.abs(startTime - endTime) / (1000 * 60 * 60 * 24);
      return dates;
    },

    /* **
     * 获取最近N天或最近N小时，返回具体的时间格式，例如：2020-02-14 13:02:03
     * obj里面的参数说明
     *  type:
     *    "year"->精确到年;
     *    "month"->精确到月;
     *    "day"->精确到天;
     *    "hour"->精确到小时;
     *    "minute"->精确到分钟;
     *    "second" - > 精确到秒;
     *  days： 要获取N天的N数值，正数往后算日期，负数往前算日期
     *  char： 非必传，默认"-"，用什么符号来连接
     *  time： 从指定时间开始计算
     ** */
    getRecentDate(obj) {
      let new_time = "";
      let def_time = obj.time || ""; // 默认时间
      let deftime_str = comfn.time.getTimeObj(def_time).time_str;
      let days_str = obj.days * 24 * 60 * 60 * 1000;
      let newtime_str = deftime_str + days_str;
      let newtime_obj = comfn.time.getTimeObj(newtime_str, obj.char);

      let opt = {
        year: newtime_obj.year,
        month: newtime_obj.ym,
        day: newtime_obj.ymd,
        hour: newtime_obj.ymdh,
        minute: newtime_obj.ymdhm,
        second: newtime_obj.ymdhms,
      };
      new_time = opt[obj.type];

      return new_time;
    },
  },

  //只能输入数字
  clearNoNum(obj) {
    obj.value = obj.value.replace(/[^\d]/g, ""); //清除"数字"和"."以外的字符
  },

  // 清除字符串中的所有html标签
  clearHtml(str) {
    if (!str) {
      return "";
    }
    return str.replace(/<[^>]+>/g, "");
  },

  // 随机取数组中任意N个元素
  getRandomArrayElements(arr, count) {
    let shuffled = arr.slice(0),
      i = arr.length,
      min = i - count,
      temp,
      index;
    while (i-- > min) {
      index = Math.floor((i + 1) * Math.random());
      temp = shuffled[index];
      shuffled[index] = shuffled[i];
      shuffled[i] = temp;
    }
    return shuffled.slice(min);
  },

  //==================================================================
  // 获取当前月份及本年度之前的月份 ,返回数组 [2020年01月,2020年02月等]
  getBeforeMonthList() {
    const nowDate = new Date();
    const year = nowDate.getFullYear();
    const month = nowDate.getMonth() + 1;
    let list = [];
    for (let i = 1; i <= month; i++) {
      let rmonth = i;
      // if (month >= 1 && month <= 9) {
      //   rmonth = '0' + rmonth
      // }
      list.push(year + "年" + (rmonth > 9 ? rmonth : "0" + rmonth) + "月");
    }
    return list;
  },
  // 深拷贝
  deepClone(obj) {
    let objClone = Array.isArray(obj) ? [] : {};
    if (obj && typeof obj === "object") {
      for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
          //判断ojb子元素是否为对象，如果是，递归复制
          if (obj[key] && typeof obj[key] === "object") {
            objClone[key] = this.deepClone(obj[key]);
          } else {
            //如果不是，简单复制
            objClone[key] = obj[key];
          }
        }
      }
    }
    return objClone;
  },

  /**
   * @description
   * @author laomao
   * @date 2021-10-30 13:05:37
   * @param { number } type 1 localStorage 2 sessionStorage
   * @param { string } key 键
   * @param { string } data 要存储的数据
   * @returns
   */
  addStorageEvent(type, key, data) {
    if (type === 1) {
      // 创建一个StorageEvent事件
      var newStorageEvent = document.createEvent("StorageEvent");
      const storage = {
        setItem(k, val) {
          localStorage.setItem(k, val);
          // 初始化创建的事件
          newStorageEvent.initStorageEvent(
            "setItem",
            false,
            false,
            k,
            null,
            val,
            null,
            null
          );
          // 派发对象
          window.dispatchEvent(newStorageEvent);
        },
      };
      return storage.setItem(key, data);
    } else {
      // 创建一个StorageEvent事件
      var newStorageEvent = document.createEvent("StorageEvent");
      const storage = {
        setItem(k, val) {
          sessionStorage.setItem(k, val);
          // 初始化创建的事件
          newStorageEvent.initStorageEvent(
            "setItem",
            false,
            false,
            k,
            null,
            val,
            null,
            null
          );
          // 派发对象
          window.dispatchEvent(newStorageEvent);
        },
      };
      return storage.setItem(key, data);
    }
  },
};

export default comfn;
