<template>
  <div class="box-404">
    <van-empty image="error" description="未找到您想查询的内容。" />
  </div>
</template>
<script>
export default {};
</script>
<style lang="scss" scoped>
.box-404 {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
