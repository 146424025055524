<template>
  <div class="iframe-box">
    <iframe :src="src" name="myIframe" class="iframe-dom" frameborder="0" ref="frameDom" :height="iframeHeight"></iframe>
  </div>
</template>
<script>
export default {
  props: {
    src: String, // 地址
  },
  data() {
    return {
      iframeHeight: 0,
    };
  },
  created() {
    this.iframeAutoHeight();
    setTimeout(() => {
      this.iframeAutoHeight();
    }, 500);
    window.addEventListener("setItem", e => {
      this.iframeAutoHeight();
    });
    window.addEventListener("resize", () => {
      this.iframeAutoHeight();
    });
  },
  mounted() {
    document.getElementsByName("myIframe")[0].onload = () => {
      this.iframeAutoHeight();
    };
  },
  methods: {
    // iframe自适应高度
    iframeAutoHeight() {
      const $dom = document.getElementsByName("myIframe")[0];
      let _height = 0;
      let $body = null;
      if ($dom?.contentWindow) {
        $body = $dom?.contentWindow?.document?.documentElement;
      } else {
        $body = $dom?.contentDocument?.body;
      }
      _height = $body?.children[1]?.children[0]?.clientHeight || $body?.scrollHeight;
      this.iframeHeight = _height + 80;
    },
  },
};
</script>
<style lang="scss" scoped>
.iframe-box {
  width: 100%;
  .iframe-dom {
    width: 100%;
  }
}
</style>
